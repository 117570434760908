/* You can add global styles to this file, and also import other style files */
/* REDUCE FONT-SIZE GLOBALLY FOR SCALING DOWN PRIMGNG COMPONENTS */
html { font-size: 12px;}
/* D3-TOOLTIP */
.ui-tooltip{word-wrap: break-word;}
.banner-border-bottom{border-bottom: 5px solid #889bff !important;}
.d3-tip {padding: 6px;background: rgba(0, 0, 0, 0.74);color: #fff;border-radius: 5px;pointer-events: none;z-index: 12000!important;font-size: 10.5px;word-wrap: break-word;max-width: 301px;font-family: sans-serif;}
.d3-tip strong{color: #fff;}
/* Creates a small triangle extender for the tooltip */
.d3-tip:after {box-sizing: border-box;display: inline;font-size: 9px;width: 100%;line-height: 1;color: rgba(0, 0, 0, 0.8);position: absolute;pointer-events: none;}
/* Northward tooltips */
.d3-tip.n:after {content: "▼";margin: -1px 0 0 0;top: 100%;left: 0;text-align: center;}
/* Eastward tooltips */
.d3-tip.e:after {content: "◀";margin: -4px 0 0 0;top: 50%;left: -8px;}
/* Southward tooltips */
.d3-tip.s:after {content: "▲";margin: 0 0 1px 0;top: -8px;left: 0;text-align: center;}
/* Westward tooltips */
.d3-tip.w:after {content: "▶";margin: -4px 0 0 -1px;top: 50%;left: 100%;}
.d3-tip:empty {display: none;}

/* ARTICLE RELATED REPORT */
.relatedreportsdialog{font-size: 11px;width: 75vw;height: auto;max-height: 90vh;top: -10px;}
.relatedreportsdialog .p-dialog-content{width: 100%;height: auto;max-height: 79vh;min-height:75vh;}
.relatedreportsdialog p-table .link {cursor: pointer;color: #0000EE;text-decoration: underline;word-wrap: break-word;}
.relatedreportsdialog p-table .link:link{color: #0000EE;text-decoration: underline;}
.relatedreportsdialog p-table .link:visited{color: #551A8B;text-decoration: underline;}
.relatedreportsdialog .previewreport-wrapper .caption-wrapper{width: 100%;padding: 5px;background: #eaedf0;border: 1px solid #dbdbdb;}
.relatedreportsdialog .previewreport-wrapper .caption-left{display: inline-block;width: 70%;text-align: left;}
.relatedreportsdialog .previewreport-wrapper .caption-right{display: inline-block;width: 30%;text-align: right;}
.relatedreportsdialog .previewreport-wrapper .iframe-wrapper{border: 1px solid #dbdbdb;padding: 0px;}
.relatedreportsdialog .previewreport-wrapper .preview-iframe{width: 100%;display: block;}

/* ARTICLE EDIT COMMENT DIALOG*/
.editcommentdialog{width: 65vw;height: auto;max-height: 50vh;}
.editcommentdialog .p-dialog-content{width: 100%;height: auto;max-height: 49vh;min-height:45vh;}
.editcommentdialog .p-dialog-content .postcomment-wrapper .left-items{display: inline-block;text-align: left;width: 8%;vertical-align: top;}
.editcommentdialog .p-dialog-content .postcomment-wrapper .right-items{display: inline-block;text-align: right;width: 92%;vertical-align: top;}
.editcommentdialog .p-dialog-content .postcomment-wrapper .button-wrapper{text-align: center;margin-bottom: 20px;}
.editcommentdialog .p-dialog-content .postcomment-wrapper .button-wrapper button{margin: 0px 5px;}
.editcommentdialog .p-dialog-content textarea.postcomment{width: 100%;}

/* ARTICLE PAGE - DISCLAIMER OVERLAYPANEL */
.disclaimer-overlay {width: 25vw;max-width: 26vw;}
.disclaimer-overlay .disclaimer-text{ font-style: italic;font-size: 12px;}

/* TEAMS MENU POPUP- MENUITEMS*/
.teams-button-menuitems{min-width: 14.5em;}
/* TEAMS SHARE TO TASK SPINNER */

.sharetaskspinner{position: fixed!important;z-index: 999;height: 10em!important;width: 10em!important;margin: auto!important;top: 0;left: 0;bottom: 0;right: 0;}
.sharetaskspinner::before{content: '';display: block;position: fixed;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(0,0,0,0.39);}